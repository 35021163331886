@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "YouTubeSansRegular";
  src: url("./assets/fonts/YouTubeSansRegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "YouTubeSansBold";
  src: url("./assets/fonts/YouTubeSansBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "YouTubeSansRegular", sans-serif;
  background-color: #0b0b0f;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.ProseMirror {
  position: relative;
}

.ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror div {
  margin: 0;
  padding: 0;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror [data-slate-placeholder] {
  color: #4b5563;
  pointer-events: none;
  position: absolute;
}

.prose {
  color: #d1d5db;
}

.prose h1,
.prose h2,
.prose h3 {
  color: white;
  margin-top: 0.5em;
  margin-bottom: 0.2em;
}

.prose strong {
  color: white;
  font-weight: 600;
}

.prose ul,
.prose ol {
  margin-left: 1em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.prose li {
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

.prose p {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  line-height: 1.4;
}
